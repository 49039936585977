import React from 'react';
import {iphoneRedeemCode} from '../link-actions';
import {DoubleSection} from "../components/layout/DoubleSection";
import {BottomCTA} from "../components/BottomCTA";
import {SingleSection} from "../components/layout/SingleSection";
import OwlCarousel from "react-owl-carousel";
import {PricingCricket} from "../components/PricingCricket";
import LogoText from "../components/logoText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLaptop, faMobileAlt, faTabletAlt} from "@fortawesome/free-solid-svg-icons";

const CricketNew = () => {
    return (
        <>
            <section id="home_cricket" className="atf-cover-bg atf-cover-bg-full atf-hero-area atf-align-items-details aft-width-two" style={{ backgroundImage: "url(assets/img/bg/kewcc_background.png)", backgroundSize: "cover", backgroundPosition: "center center", height: '700px' }}>
                <div className="container">
                    <div className="row atf-content-details align-items-center">
                      <div className="col-0 col-lg-6"></div>
                        <div className="col-12 col-lg-6 atf-single-details text-lg-left text-center" style={{ padding: "10px" }}>
                          <div style={{ padding: "30px", backgroundColor:'#783F8EC0', borderRadius: '15px' }}>
                            <div style={{fontSize:'24px', marginBottom: '15px'}}><LogoText light={true} /></div>
                            <h1 style={{fontSize: '42px', color: 'white'}}>Delayed Video Technology</h1>
                            <div style={{width: '200px', height: '5px', backgroundColor: 'yellowgreen', display:'inline-block'}}></div>
                            <h1 style={{fontSize: '20px', color: 'white', marginTop: '25px', fontFamily: 'Comfortaa'}}>The best new app for cricketers</h1>
                            <div style={{backgroundColor:'yellowgreen', padding:'10px', borderRadius:'15px', marginTop: '25px'}}>
                              <h1 style={{fontSize: '20px', color: 'white', fontFamily: 'Comfortaa', padding:'0', margin:'0'}}>AUS v IND SPECIAL</h1>
                            </div>
                            <div style={{padding:'10px'}}>
                              <h1 style={{fontSize: '18px', color: 'white', padding:'0', margin:'5px 0 5px 0', fontFamily: 'Comfortaa', lineHeight: '1.5'}}>Get 90 days free to celebrate the AUS v IND test series*</h1>
                            </div>
                            <div>
                              <a href="#" onClick={() => iphoneRedeemCode('AUSIND24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '60px', padding: '11px 10px 11px 10px' }} /></a>
                              <a href="/promo" ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '60px' }} /></a>
                            </div>
                            <div style={{fontSize: '12px', color: '#cccccc'}}>*Cancel any time. Offer expires 7 Jan 2025.</div>
                          </div>
                        </div>
                    </div>
                </div>
            </section>
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center" style={{ margin: '30px 10px 10px 10px' }}>
              <h2>Stream delayed video from your phone</h2>
              <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-black)', fontSize: '60px', marginTop: '15px' }}></FontAwesomeIcon><br/>
              <i className="fa fa-wifi" style={{transform: 'rotate(180deg)', fontSize: '52px', color: 'var(--thm-black)', padding: '30px 0px 20px 0px'}}></i><br />
              <FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>

              <h2 style={{ color: "yellowgreen", marginTop: '30px'}}>Watch replay on a second device</h2>


              <p style={{ fontSize: "18px", marginTop: "15px" }}>(TV, Tablet, Laptop, another Phone)</p>

            </div>
          </SingleSection>

          <section className="atf-about-area atf-section-padding">
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                  <img src="./assets/img/cricket/batting_example_2.png" alt="Stream from phone to tablet" style={{ width: "100%", maxWidth: "800px" }} ></img>
                  <div style={{display: 'flex', backgroundColor: 'yellowgreen', color: 'white', fontSize: '18px', padding: '10px', borderRadius: '0px 0px 10px 10px'}}>
                    <div className="col-2 align-self-center">
                      <i className="fa fa-arrow-circle-up" style={{fontSize: '40px', color: 'white'}}></i>
                    </div>
                      <div className="col-10">
                    Delayed replay shown on a tablet behind the net
                      </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-12 align-self-center">
                  <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                    <div className="text-center">
                      <div style={{ margin: '30px 10px 0px 10px' }}>
                        <h2 style={{  fontSize: '40px', padding: '30px 0px 10px 0px', lineHeight: '1.4' }}>Instant visual feedback</h2>
                        <div style={{color: 'white', display: 'inline-block', width: '52px', height: '52px', backgroundColor:'#999999', borderRadius: '30px'
                        }}>
                          <div style={{position: 'relative', top: '-17px', fontSize: '60px', fontWeight: 'bold', fontFamily: 'comfortaa'}}>=</div>
                        </div>
                        <h2 style={{color: 'yellowgreen'}}>Fast improvement</h2>
                      </div>
                    </div>
                    <div className="text-center" style={{color: '#999999', fontSize: '18px', marginTop: '50px'}}>
                      <h3>Play &gt; Watch &gt; Improve &gt; Repeat</h3>
                      <div style={{marginTop: '15px'}}>Immediate feedback means you can go through the learning loop, quickly.</div>
                    </div>

                  </div>
                </div>


              </div>
            </div>
          </section>

          <SingleSection background={'yellowgreen'}>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="text-center">
                  <h3 style={{fontFamily: 'comfortaa'}}>"The best new cricket app for players, coaches, and parents."</h3>
                </div>
              </div>
            </div>
          </SingleSection>
          <SingleSection background={'#F1F8E4'}>
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-12 align-self-center text-center">
                  <h1 style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '38px', marginBottom: '10px', fontFamily: 'Comfortaa'}}>AUS v IND SPECIAL</h1>
                  <p style={{color: 'var(--thm-black)', fontSize: '22px', marginBottom: '15px'}}>Get 90 days free to celebrate the AUS vs IND test series*</p>
                  <p style={{fontSize: '18px', marginBottom: '15px'}}>Redeem code <span style={{color: 'yellowgreen'}}>AUSIND24</span> below</p>
                  <a href="#" onClick={() => iphoneRedeemCode('AUSIND24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                  <a href="/promo"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                  <div style={{ color: "#999999", marginTop: "10px" }}>*Cancel any time. Offer expires 7 Jan 2025.</div>
                </div>
              </div>
            </div>
          </SingleSection>
          <SingleSection>
            <div className="text-center">
              <div style={{ margin: '30px 10px 0px 10px' }}>
                <h2 style={{  fontSize: '40px', marginBottom: '10px' }}>Play your shot</h2>
                <h2 style={{ color: "yellowgreen" }}>Then see an immediate replay</h2>
              </div>
            </div>
          </SingleSection>

          <section className="atf-about-area atf-section-padding">
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                    <video id="loopvideo" preload="metadata" poster="/cricket_hero.png" style={{ width: "100%", maxWidth: "800px", boxShadow: '0px 0px 20px 10px #66666666' }}
                           controls={true} autoPlay={true} muted={true} loop={true} playsInline={true}>
                      <source src="/cricket_hero.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                </div>
                <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                  <div>
                    <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                      <div className="atf-section-title">
                        <div className="atf-single-feature">
                          <div className="atf-feature-section">
                            <div className="atf-feature-icon atf-feature-heading">
                              <i className="fa fa-check-circle"></i><h3>On-the-spot correction</h3></div>
                            <div className="atf-feature-text text-center">
                              <p>Make sure you're doing the right things well. Check you aren't doing the wrong things.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                      <div className="atf-section-title">
                        <div className="atf-single-feature">
                          <div className="atf-feature-section">
                            <div className="atf-feature-icon atf-feature-heading">
                              <i className="fa fa-camera"></i><h3>Analyse frame-by-frame</h3></div>
                            <div className="atf-feature-text text-center">
                              <p>Pause replay and go through each frame. Great for coaches.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                      <div className="atf-section-title">
                        <div className="atf-single-feature">
                          <div className="atf-feature-section">
                            <div className="atf-feature-icon atf-feature-heading">
                              <i className="fa fa-globe"></i> <h3>Take it Anywhere</h3></div>
                            <div className="atf-feature-text text-center">
                              <p>Your club.  Your park.  Your home.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </section>
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <h2 style={{lineHeight: '1.5'}}>Our goal? <br/><span style={{color: 'yellowgreen'}}>To help you develop skills, faster.</span></h2>
            </div>
          </SingleSection>
            <DoubleSection
              col1={
                  <div className="text-center">
                      <h1 style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '40px', padding: '60px 10px 5px 10px'}}>For players</h1>
                      <img src={'/assets/img/cricket/for_players.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                      <h1 style={{color: 'yellowgreen', fontWeight: 'bold', fontSize: '28px', padding: '10px 10px 20px 10px'}}>Awareness of your own technique</h1>
                      <p style={{color: '#999999', fontSize: '18px'}}>Getting feedback after each ball means you can learn, change, and improve after each ball.</p>
                  </div>
              }
              col2={<div className="text-center">
                <h1 style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '40px', padding: '60px 10px 5px 10px'}}>For coaches</h1>
                <img src={'/assets/img/cricket/for_learning.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                <h1 style={{color: 'yellowgreen', fontWeight: 'bold', fontSize: '28px', padding: '10px 10px 20px 10px'}}>Pause replay to analyse and explain</h1>
                <p style={{color: '#999999', fontSize: '18px'}}>Cricketers "think" they're doing everything right.  Provide self awareness to develop young techniques faster.</p>
            </div>
              }
            />
          <p style={{height: '80px'}}>&nbsp;</p>
          { 1 == 2 &&
          <SingleSection background={'yellowgreen'}>
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-lg-2 col-md-1 col-0"></div>
                <img className="col-lg-1 col-md-2 col-3" src="./tripod_special.png" style={{marginTop: '-100px', marginBottom: '-45px'}} alt="Real time view" ></img>

                <div className="col-lg-7 col-md-8 col-9 align-self-center text-center">
                  <p style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '40px', marginBottom: '20px'}}>For Clubs</p>
                  <p style={{color: 'white', fontSize: '22px', marginBottom: '5px'}}>Give your club the edge over the opposition.</p>
                  <p style={{color: 'var(--thm-black)', fontSize: '16px', marginBottom: '30px'}}>Get PracticeLoop for your entire club and we'll include this portable tripod for free.</p>

                  <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/cricket#features-cricket'}>Club Deal</a>
                </div>
                <div className="col-lg-2 col-md-1 col-0"></div>
              </div>
            </div>
          </SingleSection>
          }


          <SingleSection background={'yellowgreen'}>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="text-center">
                  <h3 style={{fontFamily: 'comfortaa'}}>"If you're serious about improving your cricket, you need to try PracticeLoop"</h3>
                </div>
              </div>
            </div>
          </SingleSection>

          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <h2 style={{lineHeight: '1.5'}}>Batting, bowling, and fielding</h2>
            </div>
          </SingleSection>

          <SingleSection>
            <OwlCarousel className='owl-theme' loop={true} margin={10} nav={false} autoplay={true} items={3} dots={true} responsive={{0:{items:1}, 992:{items:3}}}>

              <div className='item text-center'><h3 style={{width: '100%'}}>Batting</h3><img src="/assets/img/cricket/batting_example_1.png" alt="Batting" style={{width: '400px'}} /></div>
              <div className='item text-center'><h3 style={{width: '100%'}}>Bowling</h3><video id="loopvideo" preload="metadata" style={{ width: "100%" }}
                                                       controls={true} autoPlay={true} muted={true} loop={true} playsInline={true}>
                <source src="/Bowling%20example.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video></div>
              <div className='item text-center'><h3 style={{width: '100%'}}>Fielding</h3><img src="/ipad_fielding_frame.png" alt="Fielding" /></div>
            </OwlCarousel>

          </SingleSection>

          { 1==2 &&
          <section className="atf-about-area atf-section-padding">
            <div className="container">
              <div className="row clearfix align-self-center">

                <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                  <div className="text-center">
                    <h2 style={{color: 'var(--thm-black)', fontSize: '32px', padding: '0px 10px 10px 10px'}}>Just let it run</h2>
                    <img src={'/ipad_batting_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                    <p style={{color: '#999999', fontSize: '18px'}}>Set it up in a net and let the delayed replay run. Batters and bowlers will love it.</p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-12 align-self-center">
                  <div className="text-center">
                      <div style={{fontSize: '28px', margin: '40px', backgroundColor: 'yellowgreen', color: 'white', padding: '21px', borderRadius: '100px', width: '80px', height: '80px', display: 'inline-block'}}>OR</div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                  <div className="text-center">
                    <h2 style={{color: 'var(--thm-black)', fontSize: '32px', padding: '0px 10px 10px 10px'}}>Pause and Analyse</h2>
                    <img src={'/ipad_fielding_frame.png'} alt="Replay on iPad" style={{ width: "100%", maxWidth: "400px" }} />
                    <p style={{color: '#999999', fontSize: '18px'}}>Pause the replay and go through each shot, ball, catch, or throw, frame by frame.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          }
            <DoubleSection background={'#F1F8E4'}
              col1={
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-6">
                    <div className="text-center" style={{marginBottom: '40px'}}>
                      <h2>Clubs love<br/><LogoText /></h2>
                    </div>
                  </div>
                  <div className="col-8 text-center">
                    <div className="our-team wow fadeIn">
                      <div className="pic">
                        <img src="assets/img/cricket/KCC_Premiership_Photo.png" alt=""/>
                      </div>
                      <h3 className="title">"PracticeLoop really helps us improve our skill base across the entire club"</h3>
                      <span className="post" style={{ fontSize: "18px", marginTop: '20px'}}>- Kew Cricket Club -</span>
                      <div>&nbsp;</div>
                      {//<a style={{backgroundColor:"yellowgreen", margin:"10px 10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/kewcc'}>See how Kew uses PracticeLoop</a>
                      }
                    </div>
                  </div>
                </div>
              }

              col2={
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-6">
                    <div className="text-center" style={{marginBottom: '40px'}}>
                      <h2>Parents love<br /><LogoText /></h2>
                    </div>
                  </div>
                  <div className="col-8 text-center">
                    <div className="our-team wow fadeIn">
                      <div className="pic">
                        <img src="assets/img/cricket/parents-example-photo.png" alt=""/>
                      </div>
                      <h3 className="title">"The improvement has been amazing since he can now watch himself bat."</h3>
                      <span className="post" style={{ fontSize: "18px", marginTop: '20px'}}>- Parent -</span>
                      <div>&nbsp;</div>
                      {//<a style={{backgroundColor:"yellowgreen", margin:"10px 10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/kewcc'}>See how Kew uses PracticeLoop</a>
                      }
                    </div>
                  </div>
                </div>
              }
              />
          { 1 == 2 &&
          <DoubleSection
            col1={
              <div className="atf-single-about-img text-center" >
                <div style={{ margin: '30px 10px 10px 10px' }}>
                  <h2 style={{ marginBottom: "10px", fontSize: '40px', lineHeight: "1.5" }}>Proven technology,<br /><span style={{ color: "yellowgreen" }}>now on your phone.</span></h2>
                </div>
                <p style={{ fontSize: "18px", marginTop: "25px" }}>Delayed video technology has been used in indoor nets for years, and it really helps.</p>
                <p style={{ fontSize: "18px", marginTop: "10px" }}>PracticeLoop allows you to take this technology to your club, park, or yard.</p>
                <p>&nbsp;</p>
              </div>

            }

            col2={
              <div className="atf-single-about-img text-center" >
              <img src="./assets/img/cricket/phone_shot.png" alt="Video on your phone"  />

                <h1 style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '28px', padding: '10px 10px 20px 10px'}}>All you need is a smart phone, and a second device</h1>
              </div>

            }
          />}

          <SingleSection background={'var(--thm-black)'}>
            <div className="col-12 align-self-center text-center">
              <h2 style={{ margin: '0px 10px 10px 10px', lineHeight: "1.5", color: "white" }}>The best way to try it?</h2>
              <p style={{ fontSize: "24px", color: "yellowgreen",  margin: "20px 0 20px 0" }}>Is with our<br /><strong>AUSTRALIA v INDIA</strong> special</p>
              <div style={{ color: "#EEEEEE", margin: "10px", fontSize: '18px' }}>Get a 90 day extended trial before the test series finishes.*<br /><br />Redeem code <strong style={{color:'yellowgreen'}}>AUSIND24</strong> below</div>
              <a href="#" onClick={() => iphoneRedeemCode('AUSIND24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
              <a href="/promo" ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
              <div style={{ color: "#EEEEEE", fontSize: "12px" }}>*Normally 7 days. Cancel any time. Offer expires Jan 7 2025.</div>
            </div>
          </SingleSection>
          { 1== 2 && <PricingCricket /> }
            <BottomCTA key={'cricket'} special={true} />

        </ >
    );
}

export default CricketNew;

