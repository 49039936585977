import React from "react";

export const Pricing = (props) => {
  return (<section id="features" className="atf-pricing-area atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-xl-6">
            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <h2 className="">Features and Pricing</h2>
            </div>
          </div>
        </div>


        <div className="row clearfix">
          <div className="col-lg-6 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{minHeight: '450px'}}>
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <div className="atf-pricing-name">
                    <h2 style={{lineHeight: '0.8'}}>$0 <br /><span style={{fontSize: '14px'}}>FREE</span></h2>
                    <h4>Basic</h4>
                  </div>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li className="offer-list-none"><del>Realtime view</del></li>
                  <li className="offer-list-none"><del>Cast to second device</del></li>
                  <li className="offer-list-none"><del>Pause and analyse</del></li>
                  <li className="offer-list-none"><del>Tags</del></li>
                  <li className="offer-list-none"><del>Email support</del></li>
                </ul>
              </div>
              <p style={{fontSize:"11px"}}>&nbsp;</p>
            </div>
          </div>

          <div className="col-lg-6 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <div className="atf-pricing-name">
                    <div className={"row"}>
                      <div className={"col-1"}>&nbsp;</div>
                      <h2 className="col-4" style={{lineHeight: '0.8'}}>$12.99* <br /><span style={{fontSize: '14px'}}>/year</span></h2>
                      <div className="col-2"><div style={{fontSize: '18px', marginTop: '12px', color: 'var(--thm-black)', padding: '9px', width: '45px', height: '45px', display: 'inline-block'}}>OR</div></div>
                      <h2 className="col-4" style={{lineHeight: '0.8'}}>$1.99* <br /><span style={{fontSize: '14px'}}>/month</span></h2>
                      <div className={"col-1"}>&nbsp;</div>
                    </div>
                    <h4>Pro</h4>

                  </div>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li>Realtime view</li>
                  <li>Cast to second device</li>
                  <li>Pause and analyse</li>
                  <li>Tags</li>
                  <li>Email support</li>
                </ul>
              </div>
              <p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}
